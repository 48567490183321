<template>
  <div class="default-communications-container">
    <vs-row class="items-start">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
        class="pr-5"
      >
        <vx-card class="custom-vx-card">

          <tabs
            :tabs-list="tabs"
            @handleEvent="selectedPage = $event"
            :defaultSelect="selectedPage"
          ></tabs>

          <vs-table
            v-if="activeTab === 'EmailTemplates'"
            :data="emailTemplates"
            class="mt-5 pay-activity-table stripes"
          >
            <template slot="thead">
              <vs-th width="30%">
                <div class="boxBody">
                  <div
                    class="combo-box"
                    @click="changeFilter('templateId')"
                  >
                    <label class="m-0 p-0 text-base font-medium"
                      >ID</label
                    >
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon
                        :class="
                          searchParam.sortBy === 'templateId' &&
                          searchParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_up"
                      />
                      <vs-icon
                        :class="
                          searchParam.sortBy === 'templateId' &&
                          searchParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_down"
                      />
                    </div>
                  </div>
                  <span
                    @click="resetInput($event, 'templateId')"
                    v-if="searchParam.templateId"
                    class="iconClose"
                    >X</span
                  >
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.templateId"
                    v-bind:class="{
                      isFocus: searchParam.templateId,
                      textBlue: searchParam.templateId,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th  width="30%">
                <div class="boxBody">
                  <div
                    class="combo-box"
                    @click="changeFilter('productType')"
                  >
                    <label class="m-0 p-0 text-base font-medium"
                      >Product Type</label
                    >
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon
                        :class="
                          searchParam.sortBy === 'productType' &&
                          searchParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_up"
                      />
                      <vs-icon
                        :class="
                          searchParam.sortBy === 'productType' &&
                          searchParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_down"
                      />
                    </div>
                  </div>
                  <span
                    @click="resetInput($event, 'productType')"
                    v-if="searchParam.productType"
                    class="iconClose"
                    >X</span
                  >
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.productType"
                    v-bind:class="{
                      isFocus: searchParam.productType,
                      textBlue: searchParam.productType,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th width="30%">
                <div class="boxBody">
                  <div
                    class="combo-box"
                    @click="changeFilter('templateType')"
                  >
                    <label class="m-0 p-0 text-base font-medium"
                      >Template Type</label
                    >
                    <div class="icon-combo" v-if="!filtersHidden">
                      <vs-icon
                        :class="
                          searchParam.sortBy === 'templateType' &&
                          searchParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_up"
                      />
                      <vs-icon
                        :class="
                          searchParam.sortBy === 'templateType' &&
                          searchParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_down"
                      />
                    </div>
                  </div>
                  <span
                    @click="resetInput($event, 'templateType')"
                    v-if="searchParam.templateType"
                    class="iconClose"
                    >X</span
                  >
                  <vs-input
                    v-if="!filtersHidden"
                    class="w-auto"
                    v-model="searchParam.templateType"
                    v-bind:class="{
                      isFocus: searchParam.templateType,
                      textBlue: searchParam.templateType,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th class="filter-cross" :class="{ toggle: !filtersHidden }">
                <filter-icon
                  size="1.5x"
                  :fill="filterIconColor.fill"
                  :stroke="filterIconColor.stroke"
                  @click="toggleFilter"
                ></filter-icon>
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.templateId">
                  {{ tr.templateId }}
                </vs-td>
                <vs-td :data="tr.productType">
                  {{ tr.productType }}
                </vs-td>
                <vs-td :data="tr.templateCategory">
                  {{ tr.templateCategory }}
                </vs-td>
                <vs-td :data="tr">
                  <u>
                    <a @click="handleEdit(tr._id, tr.templateType)">Edit</a></u
                  >
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <vs-table
            v-if="activeTab === 'SmsTemplates'"
            :data="smsTemplates"
            class="mt-5"
          >
            <template slot="thead">
              <vs-th width="30%">
                <div class="boxBody">
                  <div
                    class="combo-box"
                    @click="smsChangeFilter('templateId')"
                  >
                    <label class="m-0 p-0 text-base font-medium"
                      >ID</label
                    >
                    <div class="icon-combo" v-if="!smsFiltersHidden">
                      <vs-icon
                        :class="
                          smsSearchParam.sortBy === 'templateId' &&
                          smsSearchParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_up"
                      />
                      <vs-icon
                        :class="
                          smsSearchParam.sortBy === 'templateId' &&
                          smsSearchParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_down"
                      />
                    </div>
                  </div>
                  <span
                    @click="resetInput($event, 'templateId')"
                    v-if="smsSearchParam.templateId"
                    class="iconClose"
                    >X</span
                  >
                  <vs-input
                    v-if="!smsFiltersHidden"
                    class="w-auto"
                    v-model="smsSearchParam.templateId"
                    v-bind:class="{
                      isFocus: smsSearchParam.templateId,
                      textBlue: smsSearchParam.templateId,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th width="30%">
                <div class="boxBody">
                  <div
                    class="combo-box"
                    @click="smsChangeFilter('productType')"
                  >
                    <label class="m-0 p-0 text-base font-medium"
                      >Product Type</label
                    >
                    <div class="icon-combo" v-if="!smsFiltersHidden">
                      <vs-icon
                        :class="
                          smsSearchParam.sortBy === 'productType' &&
                          smsSearchParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_up"
                      />
                      <vs-icon
                        :class="
                          smsSearchParam.sortBy === 'productType' &&
                          smsSearchParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_down"
                      />
                    </div>
                  </div>
                  <span
                    @click="resetInput($event, 'productType')"
                    v-if="smsSearchParam.productType"
                    class="iconClose"
                    >X</span
                  >
                  <vs-input
                    v-if="!smsFiltersHidden"
                    class="w-auto"
                    v-model="smsSearchParam.productType"
                    v-bind:class="{
                      isFocus: smsSearchParam.productType,
                      textBlue: smsSearchParam.productType,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th width="30%">
                <div class="boxBody">
                  <div
                    class="combo-box"
                    @click="smsChangeFilter('templateType')"
                  >
                    <label class="m-0 p-0 text-base font-medium"
                      >Template Type</label
                    >
                    <div class="icon-combo" v-if="!smsFiltersHidden">
                      <vs-icon
                        :class="
                          smsSearchParam.sortBy === 'templateType' &&
                          smsSearchParam.sortDir === 'asc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_up"
                      />
                      <vs-icon
                        :class="
                          smsSearchParam.sortBy === 'templateType' &&
                          smsSearchParam.sortDir === 'desc'
                            ? 'dark'
                            : ''
                        "
                        icon="arrow_drop_down"
                      />
                    </div>
                  </div>
                  <span
                    @click="resetInput($event, 'templateType')"
                    v-if="smsSearchParam.templateType"
                    class="iconClose"
                    >X</span
                  >
                  <vs-input
                    v-if="!smsFiltersHidden"
                    class="w-auto"
                    v-model="smsSearchParam.templateType"
                    v-bind:class="{
                      isFocus: smsSearchParam.templateType,
                      textBlue: smsSearchParam.templateType,
                    }"
                    @change="onChangeInput($event)"
                  />
                </div>
              </vs-th>
              <vs-th class="filter-cross" :class="{ toggle: !smsFiltersHidden }">
                <filter-icon
                  size="1.5x"
                  :fill="filterIconColor.fill"
                  :stroke="filterIconColor.stroke"
                  @click="smsToggleFilter"
                ></filter-icon>
              </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.templateId">
                  {{ tr.templateId }}
                </vs-td>
                <vs-td :data="tr.productType">
                  {{ tr.productType }}
                </vs-td>
                <vs-td :data="tr.templateCategory">
                  {{ tr.templateCategory }}
                </vs-td>
                <vs-td :data="tr">
                  <u>
                    <a @click="handleEdit(tr._id, tr.templateType)">Edit</a></u
                  >
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Tabs from "@/views/components/Tabs";

import "sweetalert2/src/sweetalert2.scss";
import { FilterIcon } from "vue-feather-icons";

export default {
  components: {
    Tabs,
    FilterIcon
  },
  data() {
    return {
      filtersHidden: true,
      smsFiltersHidden: true,
      tabs: [
        { key: "EmailTemplates", text: "Email" },
        { key: "SmsTemplates", text: "Sms" },
      ],
      selectedPage: "EmailTemplates",
      clientUrl: process.env.VUE_APP_CLIENT_API,
      emailTemplates: [],
      smsTemplates: [],
      searchParam: {
        templateId: "",
        productType: "",
        templateType: "",
        sortBy: "createdAt",
        sortDir: "desc",
      },
      smsSearchParam: {
        templateId: "",
        productType: "",
        templateType: "",
        sortBy: "createdAt",
        sortDir: "desc",
      },
    };
  },
  methods: {
    ...mapActions("email", [
      "fetchAllEmailTemplates",
      "makeEmailTemplateActive",
    ]),
    ...mapActions("sms", ["fetchAllSmsTemplates", "makeSmsTemplateActive"]),
    ...mapActions("admin", ["checkLogin"]),
    async getListSetting() {
      const payload = {
        name: this.$route.name,
        partnerId: JSON.parse(localStorage.getItem("user"))._id,
      };
      await this.$store
        .dispatch("listSetting/updatelistSetting", payload)
        .then((res) => {
          this.limit = parseInt(res.data.data.limit) || this.limit;
        })
        .catch((err) => {
          
        });
    },

    async getEmailTemplates() {
      const query = new URLSearchParams(this.searchParam).toString();
      await this.fetchAllEmailTemplates(query)
        .then((res) => {
          this.emailTemplates = res.data.data.docs;
        })
        .catch((err) => {
          
        });
    },

    async getSmsTemplates() {
      const query = new URLSearchParams(this.smsSearchParam).toString();
      await this.fetchAllSmsTemplates(query)
        .then((res) => {
          this.smsTemplates = res.data.data.docs;
        })
        .catch((err) => {
          
        });
    },

    handleEdit(id, templateType) {
      if (templateType === "Email") {
        return this.$router.push({
          name: "email-templates-edit",
          params: { id: id },
        });
      }
      if (templateType === "SMS") {
        return this.$router.push({
          name: "sms-templates-edit",
          params: { id: id },
        });
      }
    },

    // function to make template active
    async makeActive(id, templateType) {
      if (templateType == "Email") {
        await this.makeEmailTemplateActive(id)
          .then((res) => {
            this.getEmailTemplates(this.pageNumber, this.limit);
            this.getSmsTemplates(this.pageNumber, this.limit);
          })
          .catch((err) => {
            
          });
      }
      if (templateType == "SMS") {
        await this.makeSmsTemplateActive(id)
          .then((res) => {
            this.getEmailTemplates();
            this.getSmsTemplates();
          })
          .catch((err) => {
            
          });
      }
    },

    // swal confirmation to make active
    confirmMakeActiveAlert(id, templateType) {
      Swal.fire({
        title: "Are you sure?",
        text: "The email template will be made active.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, do it",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          this.makeActive(id, templateType);
        }
      });
    },
    async checkAdminLogin() {
      await this.checkLogin();
    },
    toggleFilter() {
      this.filtersHidden = !this.filtersHidden;
      if (this.filtersHidden === true) {
        this.searchParam = {
          templateId: "",
          templateType: "",
          productType: "",
          sortBy: "createdAt",
          sortDir: "desc",
        };
      }
    },
    resetInput(event, id) {
      if (id == "templateId") {
        this.searchParam.templateId = "";
      } else if (id == "templateType") {
        this.searchParam.templateType = "";
      } else if (id == "productType") {
        this.searchParam.productType = "";
      } 
    },
    changeFilter(value) {
      this.searchParam.sortBy = value;
      this.searchParam.sortDir =
        this.searchParam.sortDir === "desc" ? "asc" : "desc";
    },
    smsToggleFilter() {//for sms
      this.smsFiltersHidden = !this.smsFiltersHidden;
      if (this.smsFiltersHidden === true) {
        this.smsSearchParam = {
          templateId: "",
          templateType: "",
          productType: "",
          sortBy: "createdAt",
          sortDir: "desc",
        };
      }
    },
    smsResetInput(event, id) { // for sms
      if (id == "templateId") {
        this.smsSearchParam.templateId = "";
      } else if (id == "templateType") {
        this.smsSearchParam.templateType = "";
      } else if (id == "productType") {
        this.smsSearchParam.productType = "";
      } 
    },
    smsChangeFilter(value) {
      this.smsSearchParam.sortBy = value;
      this.smsSearchParam.sortDir =
        this.smsSearchParam.sortDir === "desc" ? "asc" : "desc";
    },
    onChangeInput(val) {},
  },
  created() {
    this.getListSetting();
    this.getEmailTemplates();
    this.getSmsTemplates();
    this.checkAdminLogin();
  },
  computed: {
    filterIconColor() {
      if (!this.filtersHidden) {
        return { fill: "#0f67f4", stroke: "#0f67f4" };
      }

      return { fill: "white", stroke: "#828282" };
    },
    activeTab() {
      return this.selectedPage;
    },
  },
  watch: {
    "searchParam.templateId"() {
      this.getEmailTemplates();
    },
    "searchParam.templateType"() {
      this.getEmailTemplates();
    },
    "searchParam.productType"() {
      this.getEmailTemplates();
    },
    "searchParam.sortDir"(val) {
      this.getEmailTemplates();
    },
    "searchParam.sortBy"(val) {
      this.getEmailTemplates();
    },
    "smsSearchParam.templateId"() {
      this.getSmsTemplates();
    },
    "smsSearchParam.templateType"() {
      this.getSmsTemplates();
    },
    "smsSearchParam.productType"() {
      this.getSmsTemplates();
    },
    "smsSearchParam.sortDir"(val) {
      this.getSmsTemplates();
    },
    "smsSearchParam.sortBy"(val) {
      this.getSmsTemplates();
    },
  },
};
</script>